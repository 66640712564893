form {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  label {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #fff;
  }
  
  input[type="text"],
  input[type="email"],
  textarea {
    padding: 10px;
    border-radius: 3px;
    border: 1px solid #ccc;
    margin-bottom: 20px;
    font-size: 16px;
  }
  
  textarea {
    height: 120px;
  }
  
  button[type="submit"] {
    background-color: #4CAF50;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  
  button[type="submit"]:hover {
    background-color: #27812a;
  }
  
  @media only screen and (max-width: 768px) {
    form {
      width: 100%;
    }
  }
  